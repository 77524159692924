dmx.Component('moment', {

    initialData: {
        locale: 'en'
    },

    attributes: {
        locale: {
            type: String,
            default: 'en'
        }
    },

    methods: {
        setLocale: function(locale) {
            this.setLocale(locale);
        }
    },

    render: function(node) {
        if (!moment) alert('Moment is missing!');
        this.update({});
    },

    update: function(props) {
        //console.log('UPDATE MOMENT', props, this.props)
        if (props.locale != this.props.locale) {
            this.setLocale(this.props.locale);
        }
    },

    setLocale: function(locale) {
        moment.locale(locale == 'auto' ? window.navigator.language : locale);
        this.set('locale', moment.locale());
    }

});
